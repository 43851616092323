.Footer {
    height: 30vh;
    background-color: rgb(6,45,46);

    &__container {
        text-align: center;
        padding: 3rem
    }

    &__header {
        font-family: "Parisienne";
        font-size: 4rem;
        color: #f3e1c9;
        letter-spacing: 0.2rem;
        text-shadow: 0.1rem 0.1rem 1rem rgba(0, 0, 0, 0.644);
    }

    &__social-media-container {
        display: flex;
        margin-top: 2rem;
        justify-content: center;
    }

    &__social-media-link {
        width: 5rem;
        height: 5rem;
        background-color: rgb(2, 33, 34);
        display: flex;
        border-radius: 100%;
        justify-content: center;
        align-items: center;
        margin-right: 2.5rem;
        margin-left: 2.5rem;
    }

    &__social-icon {
        width: 2.5rem
    }
}