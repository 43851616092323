$primary-color: rgb(16, 156, 156);



$secondary-color: rgb(226, 202, 66);




$off-white: rgb(236, 231, 231);
$off-black: rgb(15, 14, 14);
$light-black: rgb(37, 37, 37);
$transparent-black-less: rgba(37, 37, 37, 0.767);
$transparent-black: rgba(0, 0, 0, 0.39)