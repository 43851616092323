@import "../../sass/variables.scss";
@import "../../sass/mixins.scss";

.Article {
    height: auto;
    padding-top: 15rem;
    font-family: "Montserrat";
    padding-bottom: 10rem;

    &__container {

    }

    &__heading {
        letter-spacing: 0.5rem;
        font-size: 5rem;
        text-align: center;
    }

    &__articles-container {
        width: 75%;
        margin: 0 auto;
        margin-top: 10rem;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        
    }

    &__card-container {
        width: 30rem;
        background-color: white;
        height: 35rem;
        border-radius: 0.3rem;
        overflow: hidden;
        box-shadow: 0.1rem 0.1rem 1rem $transparent-black;
        padding: 0.25rem;
        cursor: pointer;
        margin-bottom: 5rem;
        margin-right: 5rem;
        text-decoration: none;

        &--disabled {
            pointer-events: none;
        }
    }

    &__card-img {
        width: 100%;
        height: 50%
    }

    &__card-title {
        font-size: 2.5rem;
        margin-left: 0.5rem;
        margin-top: 0.5rem;
        color: $off-black;
    }

    &__card-description {
        margin-left: 0.5rem;
        font-size: 1.5rem;
        margin-top: 0.5rem;
        color: $light-black
    }

    &__date-and-author-container {
        display: flex;
        margin-left: 0.5rem;
        margin-top: 2rem;
        align-items: center;
        justify-content: space-between;
        color: $transparent-black-less;
    }

    &__card-author {
        font-size: 1.25rem;
    }

    &__card-data {
        font-size: 1.25rem;
        margin-right: 2.5rem;
    }
}

@include width-1350px {
    .Article {
        &__card-container {
            width: 40rem;
            
        }
    }
}

@include width-1200px {
    .Article {
        &__card-container {
            width: 35rem;
            
        }
    }
}

@include width-1200px {
    .Article {
        &__card-container {
            width: 40rem;
            margin-right: 0;
        }
        
    &__articles-container {
        justify-content: center;
        
        }
    }
}

@include width-800px {
    .Article {
        padding-top: 25rem;
    }
}


@include width-700px {
    .Article {
        padding-top: 30rem;
    }
}

@include width-600px {
    .Article {
        padding-top: 40rem;

        &__heading {
            font-size: 4rem;
        }
    }
}

@include width-500px {
    .Article {
        padding-top: 60rem;

        &__heading {
            font-size: 4rem;
        }
    }
}

@include width-450px {
    .Article {
        padding-top: 70rem;

        &__heading {
            font-size: 3rem;
        }
    }
}

@include width-400px {
    .Article {
        padding-top: 90rem;

        &__heading {
            font-size: 2.75rem;
        }
    }
}