@import "../../sass/variables.scss";
@import "../../sass/mixins.scss";

.Header {
    height: 120vh;
    background-image: url("../../assets/wallpaper.png");

    background-position: bottom;
    padding-bottom: 10rem;
    font-family: "Montserrat";
    position: relative;

    &__header-container {
        top: 20%;
        position: absolute;
        left: 5%;
        width: 47.5%
    }

    &__header {
        color: $off-white;
        font-size: 3.5rem;
        text-shadow: 0.1rem 0.5rem 1rem rgba(0, 0, 0, 0.589);

        & span {
            color: $primary-color
        };
    }

    &__description {
        color: rgb(238, 230, 230);
        font-size: 2rem;
        width: 90%;
        margin-top: 1rem;
        line-height: 2.75rem;

        & span {
            color: $secondary-color
        }
    }

    &__form {
        margin-top: 3rem;
        width: 90%;
        display: flex;
    }

    &__input {
        padding: 1.5rem;
        width: 80%;
        border-radius: 0.5rem 0 0 0.5rem;
        border: none;
        outline: none;
        font-size: 1.5rem;
    }

    &__btn {
        width: 20%;
        cursor: pointer;
        font-weight: 900;
        background-color: $primary-color;
        border: none;
        letter-spacing: 0.1rem;
        border-radius: 0 0.5rem 0.5rem 0;
        outline: none;
        color: $off-black
    }

    &__contents-container {
        position: absolute;
        width: 85%;
        transform: translate(-50%, -50%);
        position: absolute;
        top: 90%;
        left: 50%;
        height: 32.5rem;
        display: flex;
        justify-content: space-around;
        font-family: "Source Code Pro";
    }

    &__content-container {
        width: 45%;
        height: 90%;
        background-color: white;
        border-radius: 0.5rem;
        box-shadow: 0.2rem 0.2rem 1rem $transparent-black;
        padding: 1.5rem;
        text-align: center;

        &--black {
            background-color: $off-black;
        }
    }

    &__content-header {
        font-size: 2.5rem;
        text-transform: uppercase;
        letter-spacing: 0.25rem;

        &--white {
            color: $off-white
        }
    }

    &__content-description {
        font-size: 1.75rem;
        margin: 0 auto;
        margin-top: 2.5rem;
        width: 85%;
        font-weight: 600;

        &--white {
            color: $off-white
        }
    }
}


@include width-1300px {
    .Header {
    
        &__content-container {
            height: 35rem
        }
    }
}

@include width-1100px {

    .Header {

        padding-bottom: 100rem;

        &__contents-container {
            width: 95%
        }

    }
}

@include width-950px {

    .Header {

        &__contents-container {
            flex-direction: column;
            align-items: center;
        }

        &__content-container {
            width: 85%;
            margin-bottom: 5rem;
        }

    }
}

@include width-850px {

    .Header {

        &__contents-container {
            top: 91%;
        }

        &__content-container {
            
        }

    }
};

@include width-750px {

    .Header {

        &__contents-container {
            top: 93%;
        }

        &__header-container {
            width: 70%
        }

    }
};

@include width-750px {

    .Header {

        &__contents-container {
            top: 94%;
        }

    }
};

@include width-650px {

    .Header {

        &__contents-container {
            top: 96%;
        }

    }
}

@include width-600px {

    .Header {

        &__contents-container {
            top: 100%;
        }

        &__header-container {
            width: 80%
        }

    }
}


@include width-500px {

    .Header {

        &__contents-container {
            top: 120%;
        }

        &__content-container {
            height: 40.5rem
        }


        &__header-container {
            width: 90%;
            top: 22.5%
        }

    }
}


@include width-400px {

    .Header {

        &__contents-container {
            top: 130%;
        }

        &__content-container {
            height: 55.5rem;
        }


        &__header-container {
            width: 90%;
            top: 20%
        }

        &__header {
            font-size: 3rem;
        }

    }
}











