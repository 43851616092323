@mixin width-1350px {
    @media (max-width: 1350px){
        @content
    }
};

@mixin width-1300px {
    @media (max-width: 1300px){
        @content
    }
};

@mixin width-1200px {
    @media (max-width: 1200px){
        @content
    }
};

@mixin width-1150px {
    @media (max-width: 1150px){
        @content
    }
};

@mixin width-1100px {
    @media (max-width: 1100px){
        @content
    }
};

@mixin width-1000px {
    @media (max-width: 1000px){
        @content
    }
};

@mixin width-950px {
    @media (max-width: 950px){
        @content
    }
};

@mixin width-850px {
    @media (max-width: 850px){
        @content
    }
};

@mixin width-800px {
    @media (max-width: 800px){
        @content
    }
};

@mixin width-750px {
    @media (max-width: 750px){
        @content
    }
};

@mixin width-700px {
    @media (max-width: 700px){
        @content
    }
};

@mixin width-650px {
    @media (max-width: 650px){
        @content
    }
};

@mixin width-600px {
    @media (max-width: 600px){
        @content
    }
};

@mixin width-500px {
    @media (max-width: 500px){
        @content
    }
};

@mixin width-450px {
    @media (max-width: 450px){
        @content
    }
};


@mixin width-400px {
    @media (max-width: 400px){
        @content
    }
};




