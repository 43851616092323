@import "../../sass/variables.scss";
@import "../../sass/mixins.scss";

.Nav {
    height: 8vh;
    width: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    background-color: rgba(21, 79, 94, 0.247);
    border-bottom: 0.1rem solid rgba(21, 79, 94, 0.281);
    font-weight: 700;

    &__logo {
        width: 15rem;
        margin-left: 2.5rem;
        margin-top: 1.25rem;
        cursor: pointer;
    }

    &__container {
        width: 100%;
        height: 100%;
    }

    &__list {
        display: flex;
        align-items: center;
    }

    &__list-item {

        margin-right: 7.5rem;
        list-style: none;

        &--logo {
            margin-right: auto;
        }

    }

    &__link {
        text-decoration: none;
        font-size: 1.5rem;
        color: $off-black;
        text-shadow: 0.1rem 0.1rem 2rem rgba(255, 255, 255, 0.144);
    }

    &__list-items-container {
        display: flex;
    }
}

@include width-750px {
    .Nav {
        height: auto;
        padding-bottom: 2rem;


        &__list {
            flex-direction: column;
        }

        &__list-items-container {
            width: 100%;
            text-align: center;
            display: flex;
            justify-content: center;

            
        }

        &__list-item {

            width: 50%;
            margin-right: 0rem;
            font-size: 4rem;
            
            &--logo {
                width: 100%;
                display: flex;
                justify-content: center;
                
            }

        }

        &__link {
            font-size: 2.5rem;
        }

        &__logo {
            width: 37.5rem;
            
        }
    }
}

@include width-400px {
    .Nav {

        &__link {
            font-size: 2rem;
        }

        &__logo {
            width: 28.5rem;
            
        }
    }
}